import Modal from "../../components/modal";
import React from "react";
import daleImg from "../../assets/images/testimonials/dale-grant.png";

const OCOModal = () => (
  <Modal>
    <h3 className="leads-the-modal">
      OCO <span className="muted">Offices & Coworking</span>
    </h3>
    <ul className="tags">
      <li>Node.js</li>
      <li>React</li>
      <li>Redux</li>
      <li>AWS</li>
      <li>MySQL</li>
      <li>Redis</li>
    </ul>
    <p>
      OCO contacted us with a problem - their current product that sold shared
      office space in London was not converting enough leads to turn a profit.
    </p>
    <p>
      OCO prided themselves on providing a personal relationship with customers,
      assigning each lead a consultant to help them find a match for their exact
      needs. After communicating with their consultants we discovered that a
      great deal of time was being spent manually entering office data into
      Google Sheets. This data was both indirectly published to the current
      website, and also used by the consultants to store the contact details of
      the property landlords and potential interested tenants.
    </p>
    <p>
      It was clear to us that this spreadsheet was a bottleneck for the
      consultants, both in the way they published and managed offices on their
      website, and how they handled their ongoing relationship with their
      clients.
    </p>
    <p>
      Our solution was to design and develop a bespoke Office Management System
      for their business. From this system they would be able to create new
      offices to publish, assigning rich content and metadata to each entry to
      improve SEO and conversions. By creating a bespoke platform we could be
      sure to address each of their issues in the best possible way for OCOs
      business needs.
    </p>
    <p>
      As part of the project we partnered with{" "}
      <a href="http://splendidwebs.com/about-us/">Splendid Webs</a>, who
      specialise in managing software clients, and{" "}
      <a href="https://www.codeand.com.au/">Code&</a>, a capable design and UX
      studio based in Sydney. Respectively they would handle the project
      management and redesign of the OCO website, leaving Squarecat free to
      concentrate on the new software.
    </p>
    <blockquote className="testimonial">
      <div className="testimonial__content">
        <img
          src={daleImg}
          className="testimonial__author-image"
          alt=""
          role="presentation"
        />
        <p className="testimonial__text">
          "Working with Squarecat was a pleasure. We've already started planning
          our next project together!"
        </p>
      </div>
      <cite className="testimonial__author">
        Dale Grant, <a href="https://www.codeand.com.au/">Code&</a>
      </cite>
    </blockquote>
    />
  </Modal>
);

export default OCOModal;
